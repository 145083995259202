import React from "react";
import SignaturePad from 'react-signature-pad-wrapper';
import {Card, Button, Caption} from "@shopify/polaris";
import {connect} from "react-redux";
import {setSignatureContent} from "./redux/actions";

class SignatureCapture extends React.Component {

    componentDidMount() {
        this.sigPad.onEnd = () => {
            this.props.setSignature(this.sigPad.toDataURL('image/png'));
        };
        this.props.setSignature(this.sigPad);
    }


    clear = () => {
        this.sigPad.clear()
    };

    render() {
        return (
            <Card title={"Delivery Accepted"}>
                <Card.Section>
                    <Caption>
                        By signing below I indicate I accept delivery for this order as is where is
                    </Caption>
                    <SignaturePad ref={ref => this.sigPad = ref}/>
                    <Button onClick={this.clear}>
                        Clear
                    </Button>
                </Card.Section>
            </Card>
        );
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setSignature: (url) => {
            dispatch(setSignatureContent(url))
        }
    }
};
export default connect(null, dispatchToProps)(SignatureCapture);
