import React from 'react';
import {connect} from 'react-redux';
import {authorizeUser, setStore} from "../redux/actions";
import {ApiGet} from "../helpers/DeliveryManagerApiHelper";
import {AppProvider} from "@shopify/polaris";
import {API_KEY, API_SHOP_STORAGE_PROP, API_TOKEN_STORAGE_PROP, AUTH_REDIRECT_URL} from "../Configuration";
import {Provider} from "@shopify/app-bridge-react";



function WithAuthorization(Component) {

    const stateToProps = (state) => {
        return {
            authRedirect: false,
            authorization: state.authorization.is_authorized,
            shop: state.authorization.shop
        }
    };

    const dispatchToProps = (dispatch) => {

        return {
            authorizeUser: (shop) => {
                dispatch(authorizeUser(shop))
            },
            setStore: (shop) => {
                dispatch(setStore(shop))
            }
        }
    };

    class checkAuth extends React.Component {
        constructor(props) {
            super(props);
            this.state = {shop: null}
        }

        componentWillMount() {
            let local_store = localStorage.getItem(API_SHOP_STORAGE_PROP);
            const {authorizeUser, setStore, location} = this.props;
            if (local_store) {
                this.setState({shop: local_store});
            } else {
                console.log("No local store");
                const encodedString = new Buffer(location.search).toString('base64');
                if (encodedString.length < 1) {
                    //This means we don't have the parameters we need to authorize, and need to do a hard redirect.
                    this.setState({authRedirect: true});
                    return;
                }
                let params = {
                    query: encodedString
                };
                const path = 'auth/shopify/token';
                ApiGet(path, params).then((result) => {
                    if (result.data.shop) {
                        authorizeUser();
                        setStore(result.data.shop);
                        this.setState({shop: result.data.shop});
                        if (result.data.token) {
                            localStorage.setItem(API_TOKEN_STORAGE_PROP, result.data.token);
                            localStorage.setItem(API_SHOP_STORAGE_PROP, result.data.shop);
                        }
                        window.location.reload();
                    }
                });
            }
        }

        render() {
            const {shop, authRedirect} = this.state;
            const permissionUrl = `/oauth/authorize?client_id=${API_KEY}&scope=write_orders,read_customers,read_products,read_inventory&redirect_uri=${AUTH_REDIRECT_URL}`;

            if (window.top === window.self) {
                console.log("Hitting window.top");
                window.location.assign(`https://bootheel-7-ranch.myshopify.com/admin${permissionUrl}`);
            }
            const config = {apiKey: API_KEY, shopOrigin: shop};
            return (
                authRedirect ? <div>Shopify App Environment not detected. Please make sure you are loading this application within the Shopify Admin.</div> : shop ?
                    <AppProvider>
                    <Provider config={config}>
                        <Component {...this.props}/>
                    </Provider>
                    </AppProvider>:
                    <div>Loading App....</div>
            );
        }
    }


    return connect(stateToProps, dispatchToProps)(checkAuth);
}


export default WithAuthorization;
