import React from "react";
import {Card} from "@shopify/polaris";
import SettingsForm from "./settings/SettingsForm";

export default () => {
    return (
        <Card sectioned title={"Delivery Manager Settings"}>
            <SettingsForm/></Card>
    );
}
