import {ApiGet, ApiPost} from "../helpers/DeliveryManagerApiHelper";
import {
    AUTH_IS_AUTHORIZED,
    AUTH_SET_STORE,
    DELIVERY_SUCCESS,
    DISMISS_TOAST,
    ORDER_LISTING_LOADING,
    SEND_TOAST,
    UPDATE_DELIVERY_DATES,
    UPDATE_DELIVERY_LOCATIONS, UPDATE_DELIVERY_NOTE,
    UPDATE_SIGNATURE_CONTENT
} from "./reducers";

export const LOAD_ORDER = 'LOAD_ORDER';

export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';

export const orderPendingDelivery = (orders) => {
    return {
        type: LOAD_ORDERS_SUCCESS,
        payload: orders
    }
};

export function orderListIsLoading(is_loading) {
    return {
        type: ORDER_LISTING_LOADING,
        payload: is_loading
    }
}

export function fetchOrdersPendingDelivery(search = '', delivery_status = 'pending', page_size = 10, page_number = 0, sorted = [], filters = []) {
    let thunk = (dispatch) => {
        dispatch(orderListIsLoading(true));
        let query = {
            page_size: page_size,
            page_number: page_number,
            sort: sorted,
            filters: JSON.stringify(filters),
            delivery_status: delivery_status,
            search: search
        };
        let uri = 'orders';
        ApiGet(uri, query).then((response) => {
            dispatch(orderPendingDelivery(response.data));
            let delivery_dates = [];
            let delivery_addresses = [];
            response.data.map((order) => {
                if (order.delivery_date) {
                    if (delivery_dates.indexOf(order.delivery_date) < 0) {
                        delivery_dates.push(order.delivery_date);
                    }
                }
                if (order.address1) {
                    if (delivery_addresses.indexOf(order.address1) < 0) {
                        delivery_addresses.push(order.address1);
                    }
                }
                return null;
            });
            dispatch(updateDeliveryDates(delivery_dates));
            dispatch(updateDeliveryLocations(delivery_addresses));
            dispatch(orderListIsLoading(false));
        });
    };
    thunk.meta = {
        debounce: {
            time: 300,
            key: 'FETCH_ORDERS_PENDING_DELIVERY'
        }
    };
    return thunk;
}

export function updateDeliveryDates(dates) {
    return {
        type: UPDATE_DELIVERY_DATES,
        payload: dates
    }
}

export function updateDeliveryLocations(locations) {
    return {
        type: UPDATE_DELIVERY_LOCATIONS,
        payload: locations
    }
}

export function loadSingleOrder(shopify_id) {
    let thunk = (dispatch) => {
        let uri = 'orders/' + shopify_id;
        ApiGet(uri).then((result) => {
            dispatch(orderFetchSuccess(result.data));
        })

    };
    return thunk;
}

export function orderFetchSuccess(order) {
    return {
        type: LOAD_ORDER,
        payload: order
    }
}

export function setSignatureContent(url) {
    return {
        type: UPDATE_SIGNATURE_CONTENT,
        payload: url
    }
}

export function orderDeliverySuccess(order_id) {
    return {
        type: DELIVERY_SUCCESS,
        payload: order_id
    }
}

export function saveDelivery() {

    return (dispatch, getState) => {
        dispatch(orderListIsLoading(true));
        let state = getState();
        let signature = null;
        if (state.signaturePad) {
            signature = state.signaturePad.url;
        }
        let order = {
            order_id: state.currentOrder.order.id,
            signature: signature,
            note: state.deliveryManager.note
        };
        let uri = 'delivery';
        ApiPost(uri, order, []).then((result) => {
            dispatch(orderDeliverySuccess(order.order_id));
            dispatch(sendToast("Order Delivered!", 'success'));
        });
    };
}

export function sendOrderDeliveryNotifications(ids) {
    return (dispatch) => {
        dispatch(sendToast("Sending Notifications for " + ids.length.toString() + " orders"), 'info');
        ids.map((order_id) => {
            let uri = 'notifications/orders/delivery/' + order_id;
            ApiPost(uri, {order_id: order_id}).then((result) => {
                return null;
            });
            return null;
        });
        dispatch(sendToast("Notifications sent", 'success'));

    }
}

export function updateDeliveryNote(note){
    return {
        type: UPDATE_DELIVERY_NOTE,
        payload: note
    }
}
export function authorizeUser(is_authorized) {
    return {
        type: AUTH_IS_AUTHORIZED,
        payload: is_authorized
    }
}

export function setStore(shop) {
    return {
        type: AUTH_SET_STORE,
        payload: shop
    }
}

export function sendToast(message, level = 'error') {
    return {
        type: SEND_TOAST,
        payload: message,
        level: level
    }
}

export function dismissToast() {
    return {
        type: DISMISS_TOAST,
        payload: null
    }
}
