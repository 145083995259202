import React from "react";
import {connect} from "react-redux";
import {RP_GRID_FILTER_PROP} from "../Configuration";
import {
    fetchOrdersPendingDelivery,
    loadSingleOrder,
    saveDelivery,
    sendOrderDeliveryNotifications
} from "../redux/actions";
import {Card, FilterType, ResourceList} from "@shopify/polaris";

import '../App.css';

class ProductByRoute extends React.Component {
    state = {
        selectedItems: [],
        sortValue: 'DELIVERY_DATE_DESC',
        searchValue: '',
        appliedFilters: [],
        notifications_disabled: false
    };

    componentWillMount() {
        this.props.fetchOrders(this.state.searchValue);
    }

    handleSearchChange = (searchValue) => {
        this.setState({searchValue});
        this.props.fetchOrders(searchValue);
    };
    refreshOrders = () => {
        this.props.fetchOrders(this.state.searchValue);
    };
    handleFiltersChange = (appliedFilters) => {
        localStorage.setItem(RP_GRID_FILTER_PROP, JSON.stringify(appliedFilters));
        this.setState({appliedFilters});
        this.props.fetchOrders(this.state.searchValue);
    };
    handleSelectionChange = (selectedItems) => {
        this.setState({selectedItems});
    };
    renderItem = (line) => {
        const {id, quantity, unit_price, product_name, sku} = line;
        let total_price = quantity * unit_price;
        return (

            <div className="DeliveryPickItem" key={id}>
                <ResourceList.Item>
                    <h3 className="DeliveryPickItem__Sku">SKU: {sku.toString().toUpperCase()}</h3>
                    <div>Name: {product_name}</div>
                    <div className="DeliveryPickItem_Details">
                        <div className="DeliveryPickItem_Quantity">
                            QTY: {quantity}
                        </div>
                        <div className="DeliveryPickItem_Price">${parseFloat(unit_price).toFixed(2)}/ea </div>
                        <div className="DeliveryPickItem_Price">Net: ${total_price.toFixed(2)} </div>
                    </div>
                </ResourceList.Item>
            </div>

        );
    };

    render() {
        const resourceName = {
            singular: 'product',
            plural: 'products',
        };

        const {orders} = this.props;
        let items = {};
        orders.map((order) => {
            order.lines.map((line) => {
                if (items[line.product_name]) {
                    items[line.product_name].quantity = items[line.product_name].quantity + line.quantity;
                }
                else {
                    items[line.product_name] = {...line};
                }
                return null;
            });
            return null;
        });
        const pick_items = Object.keys(items).map((key) => {
            return items[key];
        });

        const filters = [
            {
                key: 'address1',
                label: 'Location',
                operatorText: 'is',
                type: FilterType.Select,
                options: this.props.delivery_locations
            }
        ];

        const applied_filters = localStorage.getItem(RP_GRID_FILTER_PROP) ? JSON.parse(localStorage.getItem(RP_GRID_FILTER_PROP)) : [];
        const filterControl = (
            <ResourceList.FilterControl
                filters={filters}
                appliedFilters={applied_filters}
                onFiltersChange={this.handleFiltersChange}
                searchValue={this.state.searchValue}
                onSearchChange={this.handleSearchChange}
                additionalAction={{
                    content: 'Refresh',
                    onAction: () => this.refreshOrders(),
                }}
            />
        );

        return (
            <Card>
                <ResourceList
                    resourceName={resourceName}
                    items={pick_items}
                    renderItem={this.renderItem}
                    filterControl={filterControl}
                    loading={this.props.loading}
                />
            </Card>
        );
    }
}


const stateToProps = (state) => {
    return {
        loading: state.deliveryManager.loading,
        orders: state.orders.orders,
        order_number: state.deliveryManager.order_id,
        delivery_dates: state.deliveryManager.delivery_dates,
        delivery_locations: state.deliveryManager.delivery_locations
    };
};

const dispatchToProps = (dispatch) => {
    return {
        fetchOrders: (search) => {
            const applied_filters = localStorage.getItem(RP_GRID_FILTER_PROP) ? JSON.parse(localStorage.getItem(RP_GRID_FILTER_PROP)) : [];
            dispatch(fetchOrdersPendingDelivery(search, 'pending', 10, 0, false, applied_filters));
        },
        loadOrder: (order_id) => {
            dispatch(loadSingleOrder(order_id));
        },
        saveDelivery: () => {
            dispatch(saveDelivery())
        },
        sendNotifications: (ids) => {
            dispatch(sendOrderDeliveryNotifications(ids));
        }
    }
};
export default connect(stateToProps, dispatchToProps)(ProductByRoute);
