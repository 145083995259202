import React from "react";
import {Button, Card, DescriptionList, Form, FormLayout, TextContainer, TextField} from "@shopify/polaris";
import {ApiGet, ApiPost} from "../helpers/DeliveryManagerApiHelper";
import {sendToast} from "../redux/actions";
import Liquid from 'liquidjs'
import {connect} from "react-redux";
import RenderLiquid from "../RenderLiquid";
import ToastComponent from "../ToastComponent";

class SettingsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: {
                sms_template: 'Insert SMS template here. Reference variables like {{TWO_BRACKETS}}',
                email_template: 'Insert Email template here. Reference variables like {{THIS}}',
                email_subject_template: 'Email Subject'
            },
            context: {
                channel_order_id: 123456,
                delivery_date: "2019-01-01",
                delivery_time: "10:00 AM",
                customer: "Sample Customer",
                address1: "1234 S Test St",
                address2: "Unit 2",
                city: "Anywhere",
                state: "CO",
                postal_code: "80123"
            },
            liquid: new Liquid()
        };
    }


    componentDidMount() {
        let uri = 'settings/delivery_manager';
        ApiGet(uri).then((result) => {
            if (result.data.configuration) {
                this.setState({templates: result.data.configuration});
            }
        })
    }

    render() {
        console.log("Templates", this.state.templates);
        const {sms_template, email_template, email_subject_template} = this.state.templates;
        const available_vars = Object.keys(this.state.context).map((property) => {
            return {
                term: property,
                description: "Reference as {{" + property + "}}"
            }
        });

        return (
            <Form onSubmit={this.handleSubmit}>
                <ToastComponent/>
                <Card title={"Notification Templates"}>
                    <Card.Section>
                        <span>In each template below, the following variables can be referenced</span>
                        <details>
                            <summary>Available Variables</summary>
                            <DescriptionList items={available_vars}/>
                        </details>
                    </Card.Section>
                    <Card.Section title={"SMS"}>
                        <TextField
                            value={sms_template}
                            multiline
                            label="SMS Template"
                            onChange={this.handleChange('sms_template')}
                            maxLength={140}
                            helpText={
                                <span>
                SMS code. Uses the Shopify Liquid template engine. SMS is limited to 140 characters.
              </span>
                            }
                        />
                        <Card.Section subdued title={"Sample Text Message"}>
                            <RenderLiquid template={sms_template} context={this.state.context}/>
                        </Card.Section>
                    </Card.Section>
                    <Card.Section title={"Email"}>
                        <FormLayout>
                            <TextField
                                value={email_subject_template}
                                onChange={this.handleChange('email_subject_template')}
                                label="Subject Template"
                                type="text"
                                helpText={
                                    <span>
                Email Subject code. Uses the Shopify Liquid template engine.
              </span>
                                }
                            />
                            <TextField
                                value={email_template}
                                onChange={this.handleChange('email_template')}
                                label="Body Template"
                                type="text"
                                multiline
                                helpText={
                                    <span>
                Email Body code. Uses the Shopify Liquid template engine.
              </span>
                                }
                            />
                            <Card.Section subdued title={"Sample HTML Email"}>
                                <Card.Section title={"Subject"}>
                                    <RenderLiquid template={email_subject_template}
                                                  context={this.state.context}/>
                                </Card.Section>
                                <Card.Section title={"Body"}>
                                    <RenderLiquid template={email_template} context={this.state.context} html/>
                                </Card.Section>
                                <Card.Section subdued title={"HTML Source"}>
                                    <TextContainer>
                                        <RenderLiquid template={email_template} context={this.state.context}/>
                                    </TextContainer>
                                </Card.Section>
                            </Card.Section>
                        </FormLayout>
                    </Card.Section>
                    <Button primary submit>Save Settings</Button>
                </Card>
            </Form>
        );
    }

    handleSubmit = (event) => {
        console.log("Event in handleSubmit", event);
        let uri = 'settings/delivery_manager';
        let request = {
            configuration: this.state.templates

        };
        ApiPost(uri, request).then((response) => {
            console.log("Response on config save", response);
            this.props.saveSuccess();
        });
    };

    handleChange = (field) => {
        return (value) => {
            this.setState({templates: {...this.state.templates, [field]: value}});
        };
    };
}

const dispatchToProps = (dispatch) => {
    return {
        saveSuccess: () => {
            dispatch(sendToast("Settings Saved", 'success'))
        }
    }
};
export default connect(null, dispatchToProps)(SettingsForm);
