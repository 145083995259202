import React from "react";
import { ResourceList, TextStyle} from "@shopify/polaris";
import Moment from "react-moment";

export default (props) => {
    console.log("PROPS IN NOTIFICATIONS", props);
    const {notifications} = props;
    return (
        <div>
            {notifications ?
                <ResourceList
                    headings={['Date', 'To', 'Message', 'Status']}
                    items={notifications} renderItem={(item) => {
                    return (<ResourceList.Item id={item.id} url={null}>

                        <div><TextStyle variation="strong">Date:</TextStyle>
                            <Moment local={true} format="lll">{item.created_at}</Moment>
                        </div>
                        <div><TextStyle variation="strong">to: </TextStyle>{item.to}</div>
                        <div><TextStyle variation="strong">Message:</TextStyle>{item.message}</div>
                        <div><TextStyle variation="strong">Status: </TextStyle>{item.status}</div>
                        {/*<div>{item.to}: {item.notification_type} | {item.message} <span> <strong>Status:</strong> {item.status}</span></div>*/}
                    </ResourceList.Item>)
                }}/> : "No notifications have been sent for this order"}
        </div>
    )
}
