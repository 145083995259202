import {combineReducers} from 'redux';
import {LOAD_ORDER, LOAD_ORDERS_SUCCESS} from "./actions";

function LoadCurrentOrder(state = {
    order: {
        lines: [],
        deliveries: [],
        notifications: []
    }
}, action) {
    switch (action.type) {
        case LOAD_ORDER:
            return {
                ...state,
                order: action.payload
            };
        default:
            return state;
    }
}

export const ORDER_LISTING_LOADING = 'ORDER_LISTING_LOADING';

function OrdersPendingDelivery(state = {orders: []}, action) {
    switch (action.type) {
        case LOAD_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload
            };
        default:
            return state;
    }
}

export const SAVE_DELIVERY = 'SAVE_DELIVERY';
export const DELIVERY_SUCCESS = 'DELIVERY_SUCCESS';

export const UPDATE_DELIVERY_DATES = 'UPDATE_DELIVERY_DATES';
export const UPDATE_DELIVERY_LOCATIONS = 'UPDATE_DELIVERY_LOCATIONS';

export const UPDATE_DELIVERY_NOTE = 'UPDATE_DELIVERY_NOTE';

function DeliveryManagerReducer(state = {
    order_id: null,
    save_delivery: false,
    delivery_dates: [],
    delivery_locations: [],
    loading: true,
    note: ''
}, action) {
    switch (action.type) {

        case UPDATE_DELIVERY_NOTE:
            return {
                ...state,
                note: action.payload
            };
        case ORDER_LISTING_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case UPDATE_DELIVERY_DATES:
            return {
                ...state,
                delivery_dates: action.payload
            };

        case UPDATE_DELIVERY_LOCATIONS:
            return {
                ...state,
                delivery_locations: action.payload
            };
        case SAVE_DELIVERY:
            return {
                ...state,
                save_delivery: true
            };
        case DELIVERY_SUCCESS:
            return {
                ...state,
                save_delivery: false
            };
        default:
            return state;
    }


}

export const UPDATE_SIGNATURE_CONTENT = 'UPDATE_SIGNATURE_CONTENT';

function SignaturePadReducer(state = {url: null}, action) {

    switch (action.type) {
        case UPDATE_SIGNATURE_CONTENT:
            return {
                ...state,
                url: action.payload
            };
        default:
            return state;
    }

}

export const AUTH_IS_AUTHORIZED = 'AUTH_IS_AUTHORIZED';
export const AUTH_SET_STORE = 'AUTH_SET_STORE';

function AuthorizationReducer(state = {is_authorized: false, store: null}, action) {
    switch (action.type) {
        case AUTH_IS_AUTHORIZED:
            return {
                ...state,
                is_authorized: action.payload,
                store: action.payload
            };
        case AUTH_SET_STORE:
            return {
                ...state,
                store: action.payload
            };
        default:
            return state;
    }
}

export const SEND_TOAST = 'SEND_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';

function ToastReducer(state = {display_toast: false, message: '', error: true}, action) {
    switch (action.type) {
        case SEND_TOAST:
            return {
                ...state,
                display_toast: true,
                message: action.payload,
                error: action.error
            };
        case DISMISS_TOAST:
            return {
                ...state,
                display_toast: false
            };
        default:
            return state;

    }
}

const RootReducer = combineReducers({
    currentOrder: LoadCurrentOrder,
    orders: OrdersPendingDelivery,
    deliveryManager: DeliveryManagerReducer,
    signaturePad: SignaturePadReducer,
    authorization: AuthorizationReducer,
    toast: ToastReducer
});
export default RootReducer;
