import React from 'react';
import {Page, TextField, Card} from '@shopify/polaris';
import {loadSingleOrder, saveDelivery, updateDeliveryNote} from "../redux/actions";
import {connect} from "react-redux";
import ToastComponent from "../ToastComponent";
import OrderLines from "./OrderLines";
import SignatureCapture from "../SignatureCapture";
import OrderHeader from "./OrderHeader";

class DeliverOrder extends React.Component {
    constructor(props) {
        super(props);
        this.handleDelivery = this.handleDelivery.bind(this);
        this.state = {canSave: true}
    }

    handleDelivery() {
        this.setState({canSave: false});
        this.props.save_delivery();
    }

    componentDidMount() {
        this.props.load_order(this.props.match.params.order_id);
    }

    render() {
        const {order} = this.props;
        const primaryAction = {
            content: "Save Delivery",
            onAction: this.handleDelivery,
            disabled: !this.state.canSave
        };

        const secondaryActions = [
            {
                content: 'Back To Queue',
                url: '/'
            }
        ];
        let lines = order.lines;
        return (
            <Page title={"Deliver " + order.order_number} primaryAction={primaryAction}
                  secondaryActions={secondaryActions}>
                <ToastComponent/>
                <OrderHeader order={order}/>
                <OrderLines lines={lines}/>
                <div>{order.note ? <Card title={"Note"}>
                    <Card.Section>
                        {order.note}
                    </Card.Section>
                </Card> : null}</div>

                <SignatureCapture order_id={order.id}/>
                <TextField label={"Delivery Note"}
                           value={this.props.note}
                           onChange={(value) => {
                               this.props.update_note(value)
                           }}
                           multiline
                readOnly={false}/>
            </Page>

        );
    }
}

const stateToProps = (state) => {
    return {
        order: state.currentOrder.order,
        loading: state.deliveryManager.loading,
        note: state.deliveryManager.note
    }
};

const dispatchToProps = (dispatch) => {
    return {
        save_delivery: (polaris) => {
            dispatch(saveDelivery(polaris))
        },
        load_order: (order_id) => {
            dispatch(loadSingleOrder(order_id));
        },
        update_note: (note) => {
            dispatch(updateDeliveryNote(note))
        }
    }
};
export default connect(stateToProps, dispatchToProps)(DeliverOrder)
