import React from 'react';
import {ResourceList, TextStyle, Card} from "@shopify/polaris";

class OrderLines extends React.Component {

    render() {
        const total_items = this.props.lines.reduce((acc, curr) => {
            return acc + curr.quantity;
        },0);
        return (
            <Card title={"Products"}>
            <ResourceList items={this.props.lines}
                          renderItem={(item) => {
                              const {id, product_name, quantity, unit_price, sku} = item;
                              let total = (unit_price * quantity).toFixed(2);
                              return (
                                  <ResourceList.Item
                                      id={id}
                                  >

                                          <TextStyle variation="strong">{product_name} {sku}</TextStyle>
                                      <div>QTY: <TextStyle variation="strong">{quantity}</TextStyle> Unit Price: ${unit_price} = Total: ${total}</div>
                                  </ResourceList.Item>
                              )
                          }}/>
                          <Card.Section>
                              Total Items: {total_items}
                          </Card.Section>
            </Card>
        );
    }
}

export default OrderLines;
