import {Toast} from "@shopify/polaris";
import {connect} from "react-redux";
import React from "react";
import {dismissToast} from "./redux/actions";

const ToastComponent = (props) => {
    console.log("PROPS IN Toast", props);
    const {display_toast, message, dismiss, error} = props;

    return display_toast ? <Toast error={error} content={message} onDismiss={dismiss}/> : null;
};
const stateToProps = (state) => {
    return {
        message: state.toast.message,
        display_toast: state.toast.display_toast,
        error: state.toast.error
    }

};

const dispatchToProps = (dispatch) => {
    return {
        dismiss: () => {
            dispatch(dismissToast())
        }
    }
};
export default connect(stateToProps, dispatchToProps)(ToastComponent);