import React from "react";
import {Card, Image} from "@shopify/polaris";
import Moment from "react-moment";

class Deliveries extends React.Component {

    renderItem(delivery) {
        return (<Card key={delivery.id}>
            <Card.Section>
                Delivered At: <Moment local={true} format="lll">{delivery.created_at}</Moment>
                <Image src={delivery.signature_url} style={{maxWidth: "90%"}}/>
            </Card.Section>
            <Card.Section title={"Delivery Note"}>
                {delivery.note ? delivery.note : 'none'}
            </Card.Section>
        </Card>);

    }

    render() {
        let children = this.props.deliveries.map((delivery) => {
            return this.renderItem(delivery);
        });
        return (
            children
        );
    }
}

export default Deliveries;
