import React, {Component} from 'react';
import {Page} from '@shopify/polaris';
import '@shopify/polaris/styles.css';
import * as PropTypes from "prop-types";
import WithAuthorization from "./auth/ConnectedAuthHelper";
import {BrowserRouter as Router, Route} from "react-router-dom";
import OrdersPendingDeliveryResource from "./orders/OrdersPendingDeliveryResource";
import ToastComponent from "./ToastComponent";
import OrderView from "./orders/OrderView";
import DeliverOrder from "./orders/DeliverOrder";
import ProductByRoute from "./route/ProductByRoute";
import Settings from "./Settings";

class App extends Component {
    static contextTypes = {
        polaris: PropTypes.object,
    };

    render() {
        return (

            <div>
                <Router>
                    <Route path="/" exact component={WithAuthorization(withShopifyRouter(OrderGrid))}/>
                    <Route path="/shopify/order" location={this.props.location}
                           component={WithAuthorization(withShopifyRouter(OrderView))}/>
                    <Route path="/deliver/:order_id" location={this.props.location}
                           component={WithAuthorization(withShopifyRouter(DeliverOrder))}/>
                    <Route path="/route/" location={this.props.location}
                           component={WithAuthorization(withShopifyRouter(ProductByRoute))}/>
                    <Route path="/settings" exact={true} location={this.props.location}
                           component={WithAuthorization(withShopifyRouter(Settings))}/>
                </Router>
            </div>
        );
    }

}

function OrderGrid() {
    return (
        <Page title={"Delivery Manager"}>
            <ToastComponent/>
            <OrdersPendingDeliveryResource/>
        </Page>);
}


function withShopifyRouter(Component) {
    return class WithRouter extends React.Component {
        static contextTypes = {
            polaris: PropTypes.object,
        };

        render() {
            return (
                <Component {...this.props}/>
            );
        }

    }
}

export default App;
