import {createStore, applyMiddleware, compose} from 'redux';
import RootReducer from "./reducers";
import thunk from "redux-thunk";
import createDebounce from 'redux-debounced';

function configureRootStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
        RootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(createDebounce(),thunk))
    );
}

const delivery_manager_store = configureRootStore();
export default delivery_manager_store;