import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as ReactDOM from "react-dom";
import delivery_manager_store from "./redux/store";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
window.SHOPIFY_APP_HOST = 'https://ec0c640c.ngrok.io';

ReactDOM.render(<Provider store={delivery_manager_store}>
    <Router><App/></Router></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
