import React from 'react';
import {connect} from "react-redux";
import {loadSingleOrder} from "../redux/actions";
import * as queryString from "query-string";
import {Card, Page} from "@shopify/polaris";
import OrderLines from "./OrderLines";
import Deliveries from "./Deliveries";
import NotificationHistory from "./NotificationHistory";
import OrderHeader from "./OrderHeader";

class OrderView extends React.Component {
    componentWillMount() {
        let values = queryString.parse(this.props.location.search);
        let shopify_id = values.id;
        if (shopify_id) {
            this.props.load_order(shopify_id);
        }
    }

    render() {

        const {order, notifications} = this.props;
        console.log("Notifications", notifications);
        return (<Page title={"Deliveries for Order " + order.order_number}>
            <Card sectioned title={"Order #" + order.order_number}>
                <OrderHeader order={order}/>
                <OrderLines lines={order.lines}/>
                <Card.Section title={"Deliveries"}>
                    <Deliveries deliveries={order.deliveries}/>
                </Card.Section>
                <Card.Section title={"Notifications"}>
                    <NotificationHistory notifications={notifications}/>
                </Card.Section>
            </Card>

        </Page>);
    }
}

const stateToProps = (state) => {
    return {
        order: state.currentOrder.order,
        notifications: state.currentOrder.order.notifications
    }
}

const dispatchToProps = (dispatch) => {
    return {
        load_order: (order_id) => {
            dispatch(loadSingleOrder(order_id));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(OrderView);
