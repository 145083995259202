import React from "react";
import {Card, Heading} from "@shopify/polaris";

export default (props) => {
    const {customer, address1, address2, city, state, postal_code} = props.order;
    const map_url = "https://maps.google.com?q=" + address1 + ' ' + city + ' ' + state + ', ' + postal_code;
    return (
        <Card title={"Customer"} actions={[{
            content: "Open Map",
            external: true,
            url: map_url,
            enabled: true
        }]}>
            <Card.Section>
                <Heading>{customer}</Heading>
                {address1}
                {address2 ? <br/> : null}
                {address2 ? address2 : null}
            </Card.Section>
        </Card>
    )
}

