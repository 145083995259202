import React from "react";
import Liquid from "liquidjs/dist/liquid";
import {TextContainer, TextStyle} from "@shopify/polaris";

class RenderLiquid extends React.Component {
    state = {
        liquid: new Liquid(),
        output: null,
        error: null,
        html: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.template !== prevProps.template) {
            console.log("re-rendering liquid");
            this.renderLiquid(this.props.template, this.props.context);
        }

    }

    componentDidMount() {
        const {template, context} = this.props;
        this.renderLiquid(template, context);

    }

    renderLiquid(template, context) {
        const {liquid} = this.state;
        liquid.parseAndRender(template, context).then((rendered) => {
            let state_update = {output: rendered, error: null};
            this.setState(state_update);
        }).catch((exception) => {
            this.setState({output: null, error: exception.message});
        })
    }

    render() {
        const {output, error} = this.state;
        const {html} = this.props;
        if (html) {
            return (
                <div>
                    <TextStyle variation={"negative"}>{error}</TextStyle>
                    <div dangerouslySetInnerHTML={{__html: output}}/>
                </div>
            );
        }
        else {
            return (
                <div>
                    <TextStyle variation={"negative"}>{error}</TextStyle>
                    <TextContainer>{output}</TextContainer>
                </div>
            );
        }


    }
}

export default RenderLiquid;
