import * as React from "react";
import {Badge, Card, FilterType, Heading, ResourceList, TextStyle} from "@shopify/polaris";
import {
    fetchOrdersPendingDelivery,
    loadSingleOrder,
    saveDelivery,
    sendOrderDeliveryNotifications
} from "../redux/actions";
import {connect} from "react-redux";
import {DM_GRID_FILTER_PROP} from "../Configuration";
import OrderLines from "./OrderLines";

class OrderDeliveryResource extends React.Component {
    state = {
        selectedItems: [],
        sortValue: 'DELIVERY_DATE_DESC',
        searchValue: '',
        appliedFilters: [],
        notifications_disabled: false
    };

    componentWillMount() {
        console.log("Component Will Found Fetch Orders");
        this.props.fetchOrders(this.state.searchValue);
    }

    handleSearchChange = (searchValue) => {
        this.setState({searchValue});
        this.props.fetchOrders(searchValue);
    };
    refreshOrders = () => {
        this.props.fetchOrders(this.state.searchValue);
    };
    handleFiltersChange = (appliedFilters) => {
        localStorage.setItem(DM_GRID_FILTER_PROP, JSON.stringify(appliedFilters));
        console.log("NEW FILTERS", appliedFilters);
        this.setState({appliedFilters});
        this.props.fetchOrders(this.state.searchValue);
    };
    handleSelectionChange = (selectedItems) => {
        this.setState({selectedItems});
    };
    renderItem = (order) => {
        const {id, channel_order_id, customer, address1, address2, notifications, lines} = order;
        let url = '/deliver/' + channel_order_id;
        // const shortcutActions = [{content: 'Deliver Order', url: '/deliver/' + channel_order_id}];
        return (
            <ResourceList.Item
                id={id}
                url={url}
                accessibilityLabel={`Deliver Order ID ${id}`}
            >
                <Card>
                <Card.Section>
                    <Heading>
                        #{order.order_number} - {customer}
                    </Heading>
                        <TextStyle>{address1}</TextStyle><br/>
                        <TextStyle variation={"subdued"}>{address2}</TextStyle>
                    <div>
                        {notifications.length ? <Badge status={"info"}>Notified</Badge> :
                            null}
                    </div>
                </Card.Section>
                <OrderLines lines={lines}/>

                <div>{order.note ? <Card title={"Note"}>
                    <Card.Section>
                        {order.note}
                    </Card.Section>
                </Card> : null}</div>
                </Card>
            </ResourceList.Item>

        );
    };

    render() {
        const resourceName = {
            singular: 'order',
            plural: 'orders',
        };

        const {orders, sendNotifications} = this.props;
        const {notifications_disabled} = this.state;

        const promotedBulkActions = [
            {
                content: 'Send Notifications',
                onAction: () => {
                    this.setState({notifications_disabled: true});
                    sendNotifications(this.state.selectedItems);
                },
                disabled: notifications_disabled
            },
        ];
        // let bulkActions = [
        //     {
        //         content: 'Send Notifications',
        //         onAction: () => console.log('Todo: implement Send Notifications'),
        //     }
        // ];
        const filters = [
            {
                key: 'address1',
                label: 'Location',
                operatorText: 'is',
                type: FilterType.Select,
                options: this.props.delivery_locations
            }
        ];

        const applied_filters = localStorage.getItem(DM_GRID_FILTER_PROP) ? JSON.parse(localStorage.getItem(DM_GRID_FILTER_PROP)) : [];
        const filterControl = (
            <ResourceList.FilterControl
                filters={filters}
                appliedFilters={applied_filters}
                onFiltersChange={this.handleFiltersChange}
                searchValue={this.state.searchValue}
                onSearchChange={this.handleSearchChange}
                additionalAction={{
                    content: 'Refresh',
                    onAction: () => this.refreshOrders(),
                }}
            />
        );
        return (
            <Card>
                <ResourceList
                    resourceName={resourceName}
                    items={orders}
                    renderItem={this.renderItem}
                    selectedItems={this.state.selectedItems}
                    onSelectionChange={this.handleSelectionChange}
                    promotedBulkActions={promotedBulkActions}
                    filterControl={filterControl}
                    loading={this.props.loading}
                />
            </Card>
        );
    }
}

const stateToProps = (state) => {
    return {
        loading: state.deliveryManager.loading,
        orders: state.orders.orders,
        order_number: state.deliveryManager.order_id,
        delivery_dates: state.deliveryManager.delivery_dates,
        delivery_locations: state.deliveryManager.delivery_locations
    };
};

const dispatchToProps = (dispatch) => {
    return {
        fetchOrders: (search) => {
            const applied_filters = localStorage.getItem(DM_GRID_FILTER_PROP) ? JSON.parse(localStorage.getItem(DM_GRID_FILTER_PROP)) : [];
            dispatch(fetchOrdersPendingDelivery(search, 'pending', 10, 0, false, applied_filters));
        },
        loadOrder: (order_id) => {
            dispatch(loadSingleOrder(order_id));
        },
        saveDelivery: () => {
            dispatch(saveDelivery())
        },
        sendNotifications: (ids) => {
            dispatch(sendOrderDeliveryNotifications(ids));
        }
        // onLoad: () => {
        //     dispatch(fetchFilteredCustomers())
        // },
        // dispatch
    }
};
const OrdersPendingDeliveryResource = connect(stateToProps, dispatchToProps)(OrderDeliveryResource);
export default OrdersPendingDeliveryResource
