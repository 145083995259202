import axios from "axios";
import {API_TOKEN_STORAGE_PROP, DELIVERY_MANAGER_API} from "../Configuration";
import delivery_manager_store from "../redux/store";
import {authorizeUser, sendToast} from "../redux/actions";

export function doRequest(uri, method = 'GET', params = [], body = null) {
    axios.interceptors.response.use(response => response, function (error) {
        console.log("Error", error);
        if (!error.response.status) {
            console.log("Response without status", error);
            return Promise.reject("No status in response");
        }
        if (error.response.status === 401 || error.response.status === 402) {
            console.log("Received 401");
            localStorage.clear();
            delivery_manager_store.dispatch(authorizeUser(false));
            /*
            @todo here is where we would need to potentially go through the re-auth flow
             */
            delivery_manager_store.dispatch(sendToast("Auth Error - Please Refresh The Page",'error'));
            return Promise.reject('Authorization is invalid');
        }
        console.log("Making it here");
        delivery_manager_store.dispatch(sendToast("API Error",'error'));
        return Promise.reject(error);

    });
    let esc = encodeURIComponent;
    // let token = localStorage.getItem('dm-auth-key');
    let url = DELIVERY_MANAGER_API + uri;
    if (!params['token']) {
        let token = localStorage.getItem(API_TOKEN_STORAGE_PROP);
        console.log("Token decorated: " + token);
        if (token) {
            params['token'] = token;
        }
    }
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
    if (query) {
        url = url + '?' + query;
    }
    console.log("Making request", url, method);
    switch (method.toUpperCase()) {
        case 'GET':
            return axios.get(url);
        case 'POST':
            return axios.post(url, body);
        case 'PUT':
            return axios.put(url, body);
        case 'DELETE':
            return axios.delete(url);
        default:
            throw new Error("Verb " + method + " not understood");
    }

}

export function ApiGet(uri, params = []) {
    return doRequest(uri, 'GET', params);
}


export function ApiPost(uri, body = {}, params = []) {
    return doRequest(uri, 'POST', params, body);
}


export function ApiPut(uri, body = {}, params = []) {
    return doRequest(uri, 'PUT', params, body);
}

export function ApiDelete(uri, params) {
    return doRequest(uri, 'DELETE', params);
}




