export const DELIVERY_MANAGER_API = 'https://deliverymanager-api.brandonallhands.com/api/';
// export const DELIVERY_MANAGER_API = 'https://deliverymanager-backend.blackbox.test/api/';

export const API_KEY = 'b70148a78d6fbd6e9104b92494e37d5b';

export const AUTH_REDIRECT_URL = 'https://blackbox-auth.brandonallhands.com/shopify/oauth/callback/deliverymanager';
// export const AUTH_REDIRECT_URL = 'https://authservice.blackbox.test/shopify/oauth/callback/deliverymanager';

export const API_TOKEN_STORAGE_PROP = 'blackbox-api-token';
export const API_SHOP_STORAGE_PROP = 'blackbox-api-shop';

export const DM_GRID_FILTER_PROP = 'delivery-grid-filters';
export const RP_GRID_FILTER_PROP = 'route-grid-filters';
